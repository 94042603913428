.form-gridpayslip {
    display: grid;
    gap: 16px;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  }
  
  .buttons23{
    display: flex;
    flex-direction: row;
  }

  .form-grouppayslip {
    display: flex;
    flex-direction: row;
  }
  
  label {
    margin-bottom: 8px;
    font-weight: bold;
  }
  
  input[type="text"],
  input[type="file"] {
    padding: 8px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .file-uploadpayslip {
    display: flex;
    align-items: center;
  }
  
  .upload-text {
    margin-left: 16px;
    font-size: 14px;
    color: #555;
  }
  
  .upload-button {
    margin-left: 16px;
  }
  
  .buttonpayslip {
    padding: 8px 16px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .buttonpayslip:hover {
    background-color: #0056b3;
  }
  