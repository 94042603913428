.marginTop3 {
    margin-top: 3rem;
  }
  
  .grid-container {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 2rem;
  }
  
  .button-back {
    background-color: transparent;
    border: 1px solid #007bff;
    color: #007bff;
    padding: 0.5rem 1rem;
    font-size: 0.875rem;
    display: flex;
    align-items: center;
    cursor: pointer;
    border-radius: 4px;
  }
  
  .button-back:hover {
    background-color: #007bff;
    color: #fff;
  }
  
  .arrow-back-icon {
    margin-right: 0.5rem;
  }
  
  .alert {
    padding: 1rem;
    border-radius: 4px;
    margin-bottom: 2rem;
  }
  
  .alert-success {
    background-color: #d4edda;
    color: #155724;
    border: 1px solid #c3e6cb;
  }
  
  .alert-title {
    font-weight: bold;
    margin-bottom: 0.5rem;
  }
  
  .button-view-mail {
    background-color: #007bff;
    color: #fff;
    padding: 0.5rem 1rem;
    font-size: 0.875rem;
    text-decoration: none;
    border-radius: 4px;
    display: inline-block;
    margin-top: 0.5rem;
  }
  
  .button-view-mail:hover {
    background-color: #0056b3;
  }
  