@media (max-width: 768px) {
    .tabs-container {
      flex-direction: column;
      align-items: center;
    }
  
    .tabts {
      width: 80%; /* Adjust as needed */
      margin-top: 5px;
    }
  }

  
  .media {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
  }

  
  .media-body {
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
  }

  .services .media-body h3 {
    font-size: 20px;
  }

  .media .ftco-icon {
    width: 100px;
  }
  .media .ftco-icon span {
    color: #A890FE;
  }

  /* Add this CSS to your stylesheet */
.media.block-6.services img {
    width: 100%; /* Adjust the width as needed */
    height: auto; /* Maintain aspect ratio */
  }


.ftco-section .heading{
    font-size: 14px;
    color:#853f91;
}


.services {
    display: block;
    width: 100%;
    -moz-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    -webkit-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    transition: all 0.3s ease;
  }
  .services .icon {
    line-height: 1.3;
    position: relative;
    width: 100px;
    height: 100px;
    background: #f8fbff;
    margin: 0 auto;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    border-radius: 50%;
  }
  .services .icon span {
    font-size: 50px;
    background: #A890FE;
    background: -moz-linear-gradient(-45deg, #A890FE 0%, #2cbcbc 100%);
    background: -webkit-gradient(
      left top,
      right bottom,
      color-stop(0%, #A890FE),
      color-stop(100%, #2cbcbc)
    );
    background: -webkit-linear-gradient(-45deg, #A890FE 0%, #2cbcbc 100%);
    background: -o-linear-gradient(-45deg, #A890FE 0%, #2cbcbc 100%);
    background: -ms-linear-gradient(-45deg, #A890FE 0%, #2cbcbc 100%);
    background: -webkit-linear-gradient(315deg, #A890FE 0%, #2cbcbc 100%);
    background: -o-linear-gradient(315deg, #A890FE 0%, #2cbcbc 100%);
    background: linear-gradient(135deg, #A890FE 0%, #2cbcbc 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#A890FE', endColorstr='#00dc94', GradientType=1 );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  .services .media-body h3 {
    font-size: 20px;
  }
  