.contactform {
    margin-left: 25%;
}

@media (max-width: 768px) {
    .contactform{
        margin-left: 0;
        display: flex;
        justify-content: center;
    }
}