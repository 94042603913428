
@media (max-width: 768px) {
  .contact-us .iframediv {
    width: 100%;
    height: auto;
    border-radius: 10px;
    box-shadow: inherit;
  }
  
  .contact-us .section-heading {
    margin-right: 0;
  }

  .contact-us .contact-us-content {
    padding: 40px 20px;
  }
}

/* filter page*/
@media (max-width: 768px) {
  .styled-button{
    width:250px;
  }

  .mapspace{
    margin-top: 30px;
  }

 .contact-us-content{
    margin-top: 30px;
  }
}

