/* 

TemplateMo 586 Scholar

https://templatemo.com/tm-586-scholar

*/

/* ---------------------------------------------
Table of contents
------------------------------------------------
01. font & reset css
02. reset
03. global styles
04. header
05. banner
06. features
07. testimonials
08. contact
09. footer

--------------------------------------------- */
/* 
---------------------------------------------
font & reset css
--------------------------------------------- 
*/
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900");
/* 
---------------------------------------------
reset
--------------------------------------------- 
*/
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
div pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
font,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
figure,
header,
nav,
section,
article,
aside,
footer,
figcaption {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
}

.clearfix:after {
  content: ".";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0;
}

.clearfix {
  display: inline-block;
}

html[xmlns] .clearfix {
  display: block;
}

* html .clearfix {
  height: 1%;
}

ul,
li {
  padding: 0;
  margin: 0;
  list-style: none;
}

header,
nav,
section,
article,
aside,
footer,
hgroup {
  display: block;
}

* {
  box-sizing: border-box;
}

html,
body {
  font-family: "Poppins", sans-serif;
  -ms-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  text-decoration: none !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: #1e1e1e;
  margin-top: 0px;
  margin-bottom: 0px;
  font-weight: 700;
}

ul {
  margin-bottom: 0px;
}

p {
  font-size: 14px;
  line-height: 28px;
  color: #4a4a4a;
}

img {
  width: 100%;
  overflow: hidden;
}

/* 
---------------------------------------------
Global Styles
--------------------------------------------- 
*/
html,
body {
  font-family: "Poppins", sans-serif;
}
:root {
  --bg-color-primary: rgb(23, 133, 130);
  --bg-color-secondary: rgb(215, 255, 253);
  --primary-color: rgb(23, 133, 130);
  --secondary-color: rgb(215, 255, 253);
}

::selection {
  background: var(--bg-color-primary);
  color: #fff;
}

::-moz-selection {
  background: var(--bg-color-primary);
  color: #fff;
}

.section {
  padding-top: 30px;
  
}

.section-heading {
  margin-bottom: 60px;
}

.section-heading h2 {
  font-size: 36px;
  font-weight: 600;
  text-transform: capitalize;
  margin: 20px 0;
  line-height: 44px;
}

.section-heading h2 em {
  color: var(--primary-color);
  font-style: normal;
}

.section-heading h6 {
  color: var(--primary-color);
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 600;
}

.section-title h2 {
  margin: 20px 0;
  font-size: 24px;
  font-weight: 600;
  text-transform: uppercase;
  line-height: 44px;
  color: var(--primary-color);
  font-style: normal;
}

.main-button a {
  display: inline-block;
  background-color: #fff;
  color: var(--primary-color);
  font-size: 14px;
  font-weight: 500;
  height: 40px;
  line-height: 40px;
  padding: 0px 25px;
  border-radius: 20px;
  transition: all 0.3s;
}

.main-button a:hover,
.main-button a:active {
  background-color: var(--bg-color-primary);
  color: #fff;
}
.primary-button {
  border: none;
  display: inline-block;
  background-color: var(--bg-color-secondary);
  color: var(--primary-color);
  font-size: 14px;
  font-weight: 500;
  height: 40px;
  line-height: 40px;
  padding: 0px 25px;
  border-radius: 20px;
  transition: all 0.3s;
}

.primary-button:hover,
.primary-button:active {
  background-color: rgb(160, 255, 252);
}
.secondary-button {
  border: none;
  display: inline-block;
  background-color: var(--bg-color-primary);
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  height: 40px;
  line-height: 40px;
  padding: 0px 25px;
  border-radius: 20px;
  transition: all 0.3s;
}

.secondary-button:hover,
.secondary-button:active {
  background-color: rgba(23, 133, 130, 0.9);
}

h2.heading {
  display: inline-block;
  background-color: var(--bg-color-primary);
  color: #fff;
  border-radius: 50px;
  padding: 0px 20px;
  font-size: 24px;
  font-weight: normal;
}

/* Header Style ...Header.js*/
.background-header {
  background-color: white !important;
  border-radius: 0px 0px 25px 25px;
  position: fixed !important;
  top: 0 !important;
  left: 0;
  right: 0;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15) !important;
  transition: all 0.5s ease;
  padding: 1rem 0; /* Adjust padding for reduced header */
}

.header-area {
  position: absolute;
  background-color: var(--bg-color-primary);
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;
  transition: all 0.5s ease;
  padding: 2rem 0;
}

.header-sticky {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  background-color: white;
  transition: all 0.5s ease;
  padding: 1.5rem 0; /* Initial padding for sticky header */
}

.header-reduced {
  padding: 1rem 0 !important; /* Reduced padding for scroll effect */
  transition: all 0.5s ease;
}

.header-area .main-nav {
  display: flex;
  transition: all 0.5s ease;
}

.header-area .main-nav .logo {
  transition: all 0.5s ease;
  display: inline-block;
}

.header-area .main-nav .logo h1 {
  font-size: 36px;
  text-transform: uppercase;
  color: #fff;
  font-weight: 600;
  margin-right: 20px;
  padding-right: 20px;
  border-right: 1px solid rgba(250, 250, 250, 0.3);
  transition: all 0.5s ease;
}

.background-header .main-nav .logo h1,
.header-reduced .main-nav .logo h1 {
  font-size: 24px; /* Reduced font size */
}

.background-header .main-nav .logo,
.background-header .main-nav #search,
.header-reduced .main-nav .logo,
.header-reduced .main-nav #search {
  display: flex;
  align-items: center;
}

.header-area .main-nav #search {
  position: relative;
}

.header-area .main-nav #search input {
  background-color: rgba(255, 255, 255, 0.1);
  height: 44px;
  width: 250px;
  border-radius: 23px;
  border: none;
  color: #fff;
  font-size: 14px;
  font-weight: 300;
  padding: 0px 20px 0px;
}

.header-area .main-nav #search input::placeholder {
  color: rgba(250, 250, 250, 0.75);
}

.header-area .main-nav #search i {
  position: absolute;
  color: #fff;
  right: 20px;
  top: 15px;
  font-size: 16px;
}

.header-area .main-nav ul.nav {
  border-radius: 0px 0px 25px 25px;
  flex-basis: 100%;
  margin-right: 0px;
  justify-content: right;
  transition: all 0.3s ease;
}

.header-area .main-nav .nav li {
  padding-left: 5px;
  padding-right: 5px;
  height: 40px;
  line-height: 40px;
}

.header-area .main-nav .nav li a {
  display: block;
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 20px;
  font-weight: 300;
  font-size: 14px;
  height: 40px;
  line-height: 40px;
  text-transform: capitalize;
  color: #fff;
  transition: all 0.4s ease;
  border: transparent;
  letter-spacing: 0.25px;
}

.header-area .main-nav .nav li a.active {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.1);
  opacity: 1;
}

.header-area .main-nav .menu-trigger {
  cursor: pointer;
  position: absolute;
  top: 33px;
  width: 32px;
  height: 40px;
  text-indent: -9999em;
  z-index: 99;
  right: 20px;
  display: none;
}

.header-area .main-nav .menu-trigger span,
.header-area .main-nav .menu-trigger span:before,
.header-area .main-nav .menu-trigger span:after {
  transition: all 0.4s;
  background-color: #fff;
  display: block;
  position: absolute;
  width: 30px;
  height: 2px;
  left: 0;
}

.header-area .main-nav .menu-trigger span:before,
.header-area .main-nav .menu-trigger span:after {
  transition: all 0.4s;
  background-color: #fff;
  display: block;
  position: absolute;
  width: 30px;
  height: 2px;
  left: 0;
  width: 75%;
}

.header-area .main-nav .menu-trigger span:before,
.header-area .main-nav .menu-trigger span:after {
  content: "";
}

.header-area .main-nav .menu-trigger span {
  top: 16px;
}

.header-area .main-nav .menu-trigger span:before {
  transform-origin: 33% 100%;
  top: -10px;
  z-index: 10;
}

.header-area .main-nav .menu-trigger span:after {
  transform-origin: 33% 0;
  top: 10px;
}

.header-area .main-nav .menu-trigger.active span,
.header-area .main-nav .menu-trigger.active span:before,
.header-area .main-nav .menu-trigger.active span:after {
  background-color: transparent;
  width: 100%;
}

.header-area .main-nav .menu-trigger.active span:before {
  transform: translateY(6px) translateX(1px) rotate(45deg);
  background-color: #fff;
}

.header-area .main-nav .menu-trigger.active span:after {
  transform: translateY(-6px) translateX(1px) rotate(-45deg);
  background-color: #fff;
}

.header-area.header-sticky .nav li a.active {
  color: #fff;
}
.user-icon {
  color: #fff;
}

/* 
---------------------------------------------
Drop down menu style
--------------------------------------------- 
*/

.dropdown-menu {
  scrollbar-width: none;
  background-color: var(--bg-color-primary);
  max-width: 300px; /* Increase width to fit content */
  max-height: 100px; /* Remove max height to prevent scrolling */
  text-align: center;
  padding: 10px; /* Add some padding for better spacing */
}

.dropdown-menu a.dropdown-item {
  
  
  text-transform: uppercase;
  font-size: 14px;
  color: #853f91;
  background-color: transparent;
  display: block;
  padding: 5px 0; /* Add some padding between items */
  
}

.dropdown-menu a.dropdown-item:hover,
.dropdown-menu a.dropdown-item:active {
  background-color: rgba(255, 255, 255, 0.2);
  
}

/* Media query for screens with a maximum width of 767px */
@media (max-width: 767px) {
  /* Styling for the background color of the navigation */
  .background-header .main-nav .nav,
  .header-area .main-nav .nav {
    
    background-color: #f2c5fa;
  }

  /* Styling for the links in the navigation */
  .background-header .main-nav .nav li a,
  .header-area .main-nav .nav li a {
    line-height: 50px;
    height: 50px;
    font-weight: 400;
    color: #1e1e1e;
    background-color: #f2c5fa; /* Background color */
    border-radius: 0px;
  }

  /* Styling for navigation list items */
  .background-header .main-nav .nav li,
  .header-area .main-nav .nav li {
    border-top: 1px solid #ddd;
    background-color: var(--bg-color-secondary); /* Background color */
    height: 50px;
    /* border-radius: 0px 0px 25px 25px; */
  }

  /* Styling for the last navigation list item */
  .background-header .main-nav .nav li:last-child,
  .header-area .main-nav .nav li:last-child {
    border-radius: 0px 0px 25px 25px;
  }

  /* Styling for the navigation */
  .header-area .main-nav .nav {
    height: auto;
    flex-basis: 100%;
  }

  /* Styling for the logo in the navigation */
  .header-area .main-nav .logo {
    position: absolute;
    left: 30px;
    top: 0px;
  }

  /* Styling for the sticky background header */
  .background-header .main-nav .logo {
    top: 0px;
  }

  /* Styling for the border button */
  .background-header .main-nav .border-button {
    top: 0px !important;
  }

  /* Styling for the border button */
  .header-area .main-nav .border-button {
    position: absolute;
    top: 15px;
    right: 70px;
  }

  /* Styling for navigation list items on hover and when active */
  .header-area.header-sticky .nav li a:hover,
  .header-area.header-sticky .nav li a:active {
    color: #853f91;
    opacity: 1;
  }
  .header-area .main-nav .nav li a.active {
    background-color: white;
    opacity: 1;
  }
  .header-area .main-nav .nav li:hover a.active {
    color: white;
  }

  /* Styling for the search icon in the navigation */
  .header-area.header-sticky .nav li.search-icon a {
    width: 100%;
  }

  /* Styling for the header area */
  .header-area {
    background-color: white;
    padding: 0px 15px;
    height: 80px;
    box-shadow: none;
    text-align: center;
  }

  /* Styling for the container */
  .header-area .container {
    padding: 0px;
  }

  /* Styling for the logo */
  .header-area .logo {
    margin-left: 0px;
    margin-top: 30px;
  }

  /* Styling for the menu trigger */
  .header-area .menu-trigger {
    display:flex !important;
    margin-top: -15px;
    margin-right: 10px;
  }

  /* Styling for the main navigation */
  .header-area .main-nav {
    overflow: visible;
  }

  /* Styling for the navigation list */
  .header-area .main-nav .nav {
    float: none;
    width: 100%;
    display: none;
    -webkit-transition: all 0s ease 0s;
    -moz-transition: all 0s ease 0s;
    -o-transition: all 0s ease 0s;
    transition: all 0s ease 0s;
    margin-left: 0px;
    text-align: center;
    
  }

  /* Styling for the first navigation list item */
  .header-area .main-nav .nav li:first-child {
    border-top: 1px solid #eee;
  }

  /* Styling for the sticky navigation */
  .header-area.header-sticky .nav {
    margin-top: 100px !important;
  }

  /* Styling for the sticky background header */
  .background-header.header-sticky .nav {
    margin-top: 60px !important;
  }

  /* Styling for navigation list items */
  .header-area .main-nav .nav li {
    width: 100%;
    padding-left: 0px !important;
    padding-right: 0px !important;
    border: 1px solid white;
  }
  .header-area .main-nav .nav .dropdown1 li {
    width: 100%;
    padding-left: 0px !important;
    padding-right: 0px !important;
    border-color: #f2c5fa;
    border: none;
    
  }
  .user-icon {
    color: var(--primary-color);
  }
  .dropdown .mbileico{
    margin-left: 30px;
  }
}

/* Media query for screens with a maximum width of 1200px */

@media (max-width: 1200px) {
  .header-area .main-nav .nav li {
    padding-left: 2px;
    padding-right: 2px;
  }
  .header-area .main-nav .nav li a {
    padding-left: 10px;
    padding-right: 10px;
  }
  
}
/* 
---------------------------------------------
Blog style
--------------------------------------------- 
*/
.blog-section.section {
  margin-top: 120px;
}
.blog-section .section-heading {
  margin-bottom: 40px;
}
/* 
---------------------------------------------
Profile style
--------------------------------------------- 
*/
.form-group1 > label {
  margin-bottom: 0.2rem;
}
.radio-container input {
  margin-right: 0.5rem;
}
.profile-section.section {
  margin-top: 120px;
}
.profile-section .section-heading {
  margin-bottom: 40px;
}

/* 
---------------------------------------------
Seo style
--------------------------------------------- 
*/

.seo-section.section {
  margin-top: 120px;
}

.seo-section .section-heading {
  margin-bottom: 40px;
}
/* 

/* 
---------------------------------------------
Admin style
--------------------------------------------- 
*/

.admin-section.section {
  margin-top: 120px;
}

.admin-section .section-heading {
  margin-bottom: 40px;
}

.full-calender {
  font-size: 1rem;
}

.admin-section label {
  font-weight: 500;
  color: var(--primary-color);
}
/* 
---------------------------------------------
Blog Edit style
--------------------------------------------- 
*/

/* .blog-edit-section.section {
  margin-top: 120px;
} */

.tags-input {
  display: inline-block;
  position: relative;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 5px;
  box-shadow: 2px 2px 5px #00000033;
  width: 50%;
}

.tags-input ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.tags-input li {
  display: inline-block;
  background-color: #f2f2f2;
  color: #333;
  border-radius: 20px;
  padding: 5px 10px;
  margin-right: 5px;
  margin-bottom: 5px;
  font-size: 12px;
}

.tags-input input[type="text"] {
  border: none;
  outline: none;
  padding: 5px;
  font-size: 15px;
}

.tags-input input[type="text"]:focus {
  outline: none;
}

.tags-input .delete-button {
  background-color: transparent;
  border: none;
  color: #999;
  cursor: pointer;
  margin-left: 5px;
}
.blog-slide {
  position: relative;
  margin: auto;
  background: #fff;
  /* box-shadow: 0px 10px 20px rgba(0, 123, 255, 0.5); */
  box-shadow: 0px 0px 20px rgba(23, 133, 130, 0.6);
  border-radius: 40px;
  transition: all 0.3s;
  width: 100%;
  max-width: 600px;
  /* width: 50%; */
  margin-top: 40px;
}

/* Add-Update-Delete Blog Buttons  */
.blog-buttons {
  display: inline-block;
  font-size: 14px;
  font-weight: 500;
  padding: 12px 20px;
  border-radius: 25px;
  transition: all 0.3s;
}
.add-blog-button,
.add-submit-button {
  border: 2px solid var(--primary-color);
  background-color: var(--bg-color-primary);
  color: var(--secondary-color);
}
.add-blog-button-active,
.add-submit-button:hover,
.btn-primary.add-submit-button:active {
  border: 2px solid var(--primary-color);
  background-color: transparent;
  color: var(--primary-color);
}
.update-blog-button,
.update-submit-button {
  border: 2px solid #577b8d;
  background-color: #577b8d;
  color: #fff;
}
.update-blog-button-active,
.update-submit-button:hover,
.btn-primary.update-submit-button:active {
  border: 2px solid #577b8d;
  background-color: transparent;
  color: #577b8d;
}
.delete-blog-button,
.delete-submit-button {
  border: 2px solid #ef4040;
  background-color: #ef4040;
  color: #fff;
}
.delete-blog-button-active,
.delete-submit-button:hover,
.btn-primary.delete-submit-button:active {
  border: 2px solid #ef4040;
  background-color: transparent;
  color: #ef4040;
}

/* 
---------------------------------------------
Blog Page style
--------------------------------------------- 
*/
.blog-item {
  background-color: var(--bg-color-secondary);
  border-radius: 25px;
}
.blogs .blog-item .blog-image {
  position: relative;
}
.blogs .blog-item .blog-image img {
  position: absolute;
  top: -70px;
  left: 0px;
  border-radius: 25px;
  width: 100%;
  max-width: 260px;
  height: 200px;
  object-fit: cover;
  object-position: center;
}
.blogs .blog-item .blog-content {
  margin-top: 150px;
}
.blogs .blog-item .blog-content .blog-date {
  display: block;
  color: #7b7992;
  font-weight: 500;
  font-size: 14px;
}
.blogs .blog-item .blog-content .blog-title {
  font-size: 24px;
  font-weight: 700;
  color: #0d0925;
}
.blogs .blog-item .blog-content .blog-description {
  color: #4e4a67;
  max-height: 3.5em;
  overflow: hidden;
}

.blogs .blog-item .blog-content .blog-button {
  display: inline-block;
  background-color: #fff;
  color: var(--primary-color);
  font-size: 14px;
  font-weight: 500;
  height: 40px;
  line-height: 40px;
  padding: 0px 20px;
  border-radius: 20px;
  transition: all 0.3s;
}
.blogs .blog-item .blog-content .blog-button:hover,
.blogs .blog-item .blog-content .blog-button:hover {
  background-color: var(--bg-color-primary);
  color: #fff;
}
@media screen and (min-width: 576px) {
  .blog-item {
    height: 300px;
  }
  .blogs .blog-item .blog-image img {
    top: 0;
    left: -90px;
  }
  .blogs .blog-item .blog-content {
    margin-top: 0px;
    margin-left: 200px;
  }
}
@media screen and (min-width: 992px) {
  .blogs .blog-item .blog-content {
    margin-top: 0px;
    margin-left: -90px;
  }
  .nav .henav{
    position: absolute;
    flex-direction: row;
    
  }

}

/* 
---------------------------------------------
Blog Details style
--------------------------------------------- 
*/

.quote {
  font-size: 1.2rem;
  font-style: italic;
  color: #333;
  background-color: #f9f9f9;
  padding: 10px;
  border-left: 5px solid #ccc;
}

/* 
---------------------------------------------
Pagination style
--------------------------------------------- 
*/

/* Pagination item container */
.page-item {
  display: inline-block;
  margin: 0 5px;
}
/* Pagination link styling */
.page-link {
  border-radius: 25px;
  padding: 5px 15px;
  text-decoration: none;
  background-color: var(--bg-color-secondary);
  color: var(--primary-color);
  transition: background-color 0.3s, color 0.3s, transform 0.3s;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

/* Hover effect for pagination links */
.page-link:hover,
.page-link:focus {
  background-color: var(--bg-color-primary);
  color: var(--secondary-color);
  transform: translateY(-2px);
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
}

/* Active pagination link styling */
.active > .page-link,
.page-link.active {
  z-index: 3;
  color: var(--secondary-color);
  background-color: var(--bg-color-primary);
  border-color: var(--bg-color-primary);
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
}

/* 
---------------------------------------------
Blog Page style
--------------------------------------------- 
*/

/* .blog-slider {
  width: 40%;
  height: 350px;
  position: relative;
  max-width: 800px;
  margin: auto;
  background: #fff; */
/* box-shadow: 0px 10px 20px rgba(0, 123, 255, 0.5); */
/* box-shadow: rgba(23, 133, 130, 0.7) 0px 8px 24px;
  padding: 30px;
  border-radius: 25px;
  transition: all 0.3s;
  margin-left: 100px;
  margin-top: 40px;
}
@media screen and (max-width: 992px) {
  .blog-slider {
    max-width: 680px;
    height: 400px;
  }
}
@media screen and (max-width: 768px) {
  .blog-slider {
    min-height: 500px;
    height: auto;
    margin: 180px auto;
  }
}
@media screen and (max-height: 500px) and (min-width: 992px) {
  .blog-slider {
    height: 350px;
  }
}
.blog-slider__item {
  display: flex;
  align-items: center;
}
@media screen and (max-width: 768px) {
  .blog-slider__item {
    flex-direction: column;
  }
}
.blog-slider__item.swiper-slide-active .blog-slider__img img {
  opacity: 1;
  transition-delay: 0.3s;
}
.blog-slider__item.swiper-slide-active .blog-slider__content > * {
  opacity: 1;
  transform: none;
}
.blog-slider__item.swiper-slide-active .blog-slider__content > *:nth-child(1) {
  transition-delay: 0.3s;
}
.blog-slider__item.swiper-slide-active .blog-slider__content > *:nth-child(2) {
  transition-delay: 0.4s;
}
.blog-slider__item.swiper-slide-active .blog-slider__content > *:nth-child(3) {
  transition-delay: 0.5s;
}
.blog-slider__item.swiper-slide-active .blog-slider__content > *:nth-child(4) {
  transition-delay: 0.6s;
}
.blog-slider__item.swiper-slide-active .blog-slider__content > *:nth-child(5) {
  transition-delay: 0.7s;
}
.blog-slider__item.swiper-slide-active .blog-slider__content > *:nth-child(6) {
  transition-delay: 0.8s;
}
.blog-slider__item.swiper-slide-active .blog-slider__content > *:nth-child(7) {
  transition-delay: 0.9s;
}
.blog-slider__item.swiper-slide-active .blog-slider__content > *:nth-child(8) {
  transition-delay: 1s;
}
.blog-slider__item.swiper-slide-active .blog-slider__content > *:nth-child(9) {
  transition-delay: 1.1s;
}
.blog-slider__item.swiper-slide-active .blog-slider__content > *:nth-child(10) {
  transition-delay: 1.2s;
}
.blog-slider__item.swiper-slide-active .blog-slider__content > *:nth-child(11) {
  transition-delay: 1.3s;
}
.blog-slider__item.swiper-slide-active .blog-slider__content > *:nth-child(12) {
  transition-delay: 1.4s;
}
.blog-slider__item.swiper-slide-active .blog-slider__content > *:nth-child(13) {
  transition-delay: 1.5s;
}
.blog-slider__item.swiper-slide-active .blog-slider__content > *:nth-child(14) {
  transition-delay: 1.6s;
}
.blog-slider__item.swiper-slide-active .blog-slider__content > *:nth-child(15) {
  transition-delay: 1.7s;
}
.blog-slider__img {
  width: 300px;
  flex-shrink: 0;
  height: 250px;

  border-radius: 20px;
  transform: translateX(-80px);
  overflow: hidden;
  background-position: center !important;
}
.blog-slider__img:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; */
/*background-image: linear-gradient(147deg, #fe8a39 0%, #fd3838 74%);*/
/* border-radius: 20px;
  opacity: 0.8;
  background-size: contain;
}
.blog-slider__img .block-20 {
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;

  border-radius: 20px;
  transition: all 0.3s;
}
@media screen and (max-width: 768px) {
  .blog-slider__img {
    transform: translateY(-50%);
    width: 90%;
  }
}
@media screen and (max-width: 576px) {
  .blog-slider__img {
    width: 95%;
  }
}
@media screen and (max-height: 500px) and (min-width: 992px) {
  .blog-slider__img {
    height: 270px;
  }
}
.blog-slider__content {
  padding-right: 25px;
}
@media screen and (max-width: 768px) {
  .blog-slider__content {
    margin-top: -80px;
    text-align: center;
    padding: 0 30px;
  }
}
@media screen and (max-width: 576px) {
  .blog-slider__content {
    padding: 0;
  }
}
.blog-slider__content > * {
  transform: translateY(25px);
  transition: all 0.4s;
}
.blog-slider__code {
  color: #7b7992;
  margin-bottom: 15px;
  display: block;
  font-weight: 500;
}
.blog-slider__title {
  font-size: 24px;
  font-weight: 700;
  color: #0d0925;
  margin-bottom: 20px;
}
.blog-slider__text {
  color: #4e4a67;
  margin-bottom: 30px;
  line-height: 1.5em;
}
.blog-slider__button {
  display: inline-flex; */
/* background-image: linear-gradient(147deg, blue 0%, #007bff 74%); */
/* background-color: var(--bg-color-primary);
  padding: 15px 35px;
  border-radius: 50px;
  color: #fff; */
/* box-shadow: 0px 10px 20px rgba(0, 123, 255, 0.5); */
/* box-shadow: rgba(23, 133, 130, 0.7) 0px 8px 24px;
  text-decoration: none;
  font-weight: 500;
  justify-content: center;
  text-align: center;
  letter-spacing: 1px;
}
@media screen and (max-width: 576px) {
  .blog-slider__button {
    width: 100%;
  }
}
.blog-slider .swiper-container-horizontal > .swiper-pagination-bullets,
.blog-slider .swiper-pagination-custom,
.blog-slider .swiper-pagination-fraction {
  bottom: 10px;
  left: 0;
  width: 100%;
}
.blog-slider__pagination {
  position: absolute;
  z-index: 21;
  right: 20px;
  width: 11px !important;
  text-align: center;
  left: auto !important;
  top: 50%;
  bottom: auto !important;
  transform: translateY(-50%);
}
@media screen and (max-width: 768px) {
  .blog-slider__pagination {
    transform: translateX(-50%);
    left: 50% !important;
    top: 205px;
    width: 100% !important;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.blog-slider__pagination.swiper-pagination-bullets .swiper-pagination-bullet {
  margin: 8px 0;
}
@media screen and (max-width: 768px) {
  .blog-slider__pagination.swiper-pagination-bullets .swiper-pagination-bullet {
    margin: 0 5px;
  }
}
.blog-slider__pagination .swiper-pagination-bullet {
  width: 11px;
  height: 11px;
  display: block;
  border-radius: 10px;
  background: #062744;
  opacity: 0.2;
  transition: all 0.3s;
}
.blog-slider__pagination .swiper-pagination-bullet-active {
  opacity: 1;
  background: #fd3838;
  height: 30px;
  box-shadow: 0px 0px 20px rgba(252, 56, 56, 0.3);
}
@media screen and (max-width: 768px) {
  .blog-slider__pagination .swiper-pagination-bullet-active {
    height: 11px;
    width: 30px;
  }
} */

/* 
---------------------------------------------
Banner Style
--------------------------------------------- 
*/
.main-banner {
  background-color: var(--bg-color-primary);
  /* background-image: url(../images/banner-bg.jpg); */
  background-position: right top;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 130px 0px 120px 0px;
}

.main-banner .item {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  border-radius: 25px;
  padding: 70px 100px;
  /* margin-left: 130px; */
}

.main-banner .item span.category {
  background-color: var(--bg-color-primary);
  color: #fff;
  font-size: 14px;
  text-transform: uppercase;
  padding: 5px 15px;
  border-radius: 20px;
  display: inline-block;
  margin-bottom: 50px;
}

.main-banner .item h2 {
  font-size: 48px;
  font-weight: 700;
  color: #fff;
  line-height: 70px;
  width: 60%;
  margin-bottom: 40px;
}

.main-banner .item p {
  color: #fff;
  width: 65%;
}

.main-banner .item .buttons {
  display: flex;
  margin-top: 50px;
}

.main-banner .item .buttons .main-button {
  margin-right: 30px;
}

.main-banner .item .buttons .main-button a {
  font-size: 14px;
  color: var(--primary-color);
  background-color: #fff;
  display: inline-block;
  height: 50px;
  line-height: 50px;
  padding: 0px 25px;
  border-radius: 25px;
  font-weight: 500;
}

.main-banner .item .buttons .icon-button i {
  color: var(--primary-color);
  background-color: #fff;
  display: inline-block;
  height: 50px;
  width: 50px;
  text-align: center;
  line-height: 50px;
  border-radius: 50%;
  margin-right: 10px;
  font-size: 16px;
}

.main-banner .item .buttons .icon-button a {
  color: #fff;
  font-size: 14px;
  font-weight: 500;
}



/* Customize OwlCarousel Dots Navigation */
.owl-theme .owl-dots {
  text-align: center;
  margin-top: 20px;
}

.owl-theme .owl-dots .owl-dot {
  display: inline-block;
  width: 10px;
  height: 10px;
  margin: 0 5px;
  /* background-color: rgba(0, 0, 0, 0.2); */
  border-radius: 50%;
  transition: background-color 0.3s ease;
}

.owl-theme .owl-dots .owl-dot.active {
  /* background-color: rgba(0, 0, 0, 0.5);  */
}



/* 
---------------------------------------------
Services Style
--------------------------------------------- 
*/

.services .service-item:hover .icon img {
  margin-top: -10px;
}

.services .service-item {
  position: relative;
  margin-top: 95px;
}

.services .service-item .icon {
  width: 190px;
  height: 190px;
  display: inline-block;
  text-align: center;
  line-height: 190px;
  background-color: var(--bg-color-primary);
  border-radius: 50%;
  position: absolute;
  right: 0;
  top: -95px;
}

.services .service-item .icon img {
  max-width: 86px;
  transition: all 0.2s;
}

.services .service-item .main-content {
  border-radius: 25px;
  padding: 80px 30px 50px 30px;
  background-color: var(--bg-color-secondary);
  margin-bottom: 30px;
  margin-right: 80px;
  transition: all 0.4s;
}

.services .service-item h4 {
  font-size: 22px;
  font-weight: 600;
  margin-bottom: 15px;
  line-height: 30px;
  transition: all 0.4s;
}

.services .service-item p {
  color: #4a4a4a;
  margin-bottom: 25px;
}

/* 
---------------------------------------------
About Style
--------------------------------------------- 
*/

.about-us {
  position: relative;
  padding: 0px;
  margin-top: 130px;
}

.about-us:before {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: -1;
  width: 84%;
  height: 100%;
  background-color: var(--bg-color-secondary);
  content: "";
  border-top-left-radius: 500px;
  border-bottom-left-radius: 500px;
}

.accordion {
  margin-top: -40px;
  margin-bottom: -40px;
  background-color: var(--bg-color-primary);
  border-radius: 40px;
  padding: 80px 50px 50px 50px;
}

.accordion-item {
  background-color: #fff;
  border-radius: 40px !important;
  margin-bottom: 30px;
  border: none;
}

.accordion-item .accordion-button {
  outline: none;
  box-shadow: none;
  border-radius: 40px !important;
}

.accordion-button:not(.collapsed) {
  color: var(--primary-color);
  background-color: #fff;
}

h2.accordion-header button {
  padding: 15px 25px;
  font-family: "Poppins";
  font-size: 16px;
  font-weight: 600;
}

.accordion-button::after {
  font-size: 18px;
  font-weight: 500;
  background-image: none;
  content: "+";
  width: 30px;
  height: 30px;
  display: inline-block;
  text-align: center;
  line-height: 30px;
  border-radius: 50%;
  background-color: var(--bg-color-primary);
  color: #fff;
}

.accordion-button:not(.collapsed)::after {
  background-image: none;
  line-height: 32px;
  content: "-";
}

.accordion-body {
  padding: 0px 25px 30px 25px;
  font-size: 14px;
  line-height: 28px;
  color: #4a4a4a;
}

.about-us .section-heading {
  margin-left: 60px;
  margin-bottom: 0px;
}

.about-us .section-heading .main-button {
  margin-top: 50px;
}

/* 
---------------------------------------------
Courses Style
--------------------------------------------- 
*/

.courses {
  margin-top: 160px;
}

.event_filter {
  text-align: center;
}

.event_filter {
  background-color: var(--bg-color-secondary);
  border-radius: 50px;
  padding: 15px 40px;
  list-style: none;
  margin: 0 auto 70px;
  max-width: max-content;
}

.event_filter li {
  display: inline-block;
  margin: 0px 20px;
}

.event_filter li a {
  font-size: 14px;
  font-weight: 500;
  color: #1e1e1e;
  transition: all 0.3s;
}

.event_filter li .event_filter li a.is_active,
.event_filter li a:hover {
  color: var(--primary-color);
}

.events_item .thumb {
  position: relative;
  border-radius: 25px;
  overflow: hidden;
}

.events_item .thumb img {
  margin-bottom: -5px;
}

.events_item .thumb span.category {
  position: absolute;
  left: 30px;
  top: 30px;
  font-size: 14px;
  text-transform: uppercase;
  color: var(--primary-color);
  background-color: rgba(250, 250, 250, 0.95);
  padding: 8px 20px;
  border-radius: 25px;
  font-weight: 500;
  display: inline-block;
}

.events_item .thumb span.price {
  position: absolute;
  right: -30px;
  top: -50px;
  /* background-color: rgba(122, 106, 216, 0.95); */
  background-color: var(--bg-color-primary);
  width: 130px;
  height: 130px;
  border-radius: 50%;
  display: inline-block;
  transition: all 0.3s;
}

.events_item .thumb span.price h6 {
  margin-top: 72px;
  margin-left: 22px;
  font-size: 28px;
  text-transform: uppercase;
  color: #fff;
  font-weight: 700;
}

.events_item .thumb span.price em {
  font-size: 18px;
  font-weight: 500;
  font-style: normal;
  vertical-align: top;
}

.events_item {
  background-color: var(--bg-color-secondary);
  border-radius: 25px;
  margin-bottom: 30px;
  position: relative;
  justify-content: center;
  align-items: center;
}

.events_item .down-content {
  padding: 20px 30px 25px 30px;
}

.events_item .down-content span.author {
  color: var(--primary-color);
  font-size: 15px;
  display: inline-block;
  margin-bottom: 6px;
}

.events_item .down-content h4 {
  font-size: 22px;
  font-weight: 600;
  transition: all 0.3s;
}

.events_item:hover .down-content h4 {
  color: var(--primary-color);
}

.events_item:hover .thumb span.price {
  transform: scale(1.2);
}

.my-masonry-grid {
  display: flex;
  margin-left: -30px; /* gutter size offset */
  width: auto;
}

.my-masonry-grid_column {
  padding-left: 30px; /* gutter size */
  background-clip: padding-box;
}

/* Style for individual items */
.my-masonry-grid_column > div {
  margin-bottom: 30px;
}

/* 
---------------------------------------------
Facts Style
--------------------------------------------- 
*/

.fun-facts {
  position: relative;
  padding: 120px 0px 80px 0px;
  overflow: hidden;
}

.fun-facts:before {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  width: 95%;
  height: 100%;
  background-color: var(--bg-color-primary);
  content: "";
  border-top-right-radius: 500px;
  border-bottom-right-radius: 500px;
}

.fun-facts:after {
  /* background: url(../images/contact-dec-01.png); */
  position: absolute;
  left: 15%;
  opacity: 0.5;
  top: 0;
  width: 318px;
  height: 119px;
  content: "";
  z-index: 2;
}

.fun-facts .counter {
  text-align: center;
  margin-bottom: 40px;
}

.fun-facts h2 {
  color: #fff;
  font-size: 48px;
  font-weight: 700;
}

.fun-facts h2::after {
  content: "+";
  margin-left: 5px;
}

.fun-facts p {
  color: #fff;
  font-size: 15px;
  font-weight: 500;
  margin-top: 15px;
}

/* 
---------------------------------------------
Team Style
--------------------------------------------- 
*/

.team {
  margin-top: 100px;
  padding-top: 140px;
}

.team-member {
  position: relative;
}

.team-member:hover img {
  top: -120px;
}

.team-member img {
  max-width: 220px;
  border-radius: 50%;
  position: absolute;
  top: -110px;
  left: 50%;
  transform: translateX(-110px);
  transition: all 0.3s;
}

.team-member .main-content {
  border-radius: 25px;
  padding: 140px 30px 40px 30px;
  background-color: var(--bg-color-secondary);
  text-align: center;
}

.team-member .main-content span.category {
  color: var(--primary-color);
  font-size: 15px;
}

.team-member .main-content h4 {
  font-size: 22px;
  font-weight: 600;
  margin-top: 8px;
  margin-bottom: 15px;
}

.team-member .main-content ul li {
  display: inline-block;
  margin: 0px 2px;
}

.team-member .main-content ul li a {
  background-color: #fff;
  color: var(--primary-color);
  display: inline-block;
  text-align: center;
  line-height: 40px;
  font-size: 18px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  transition: all 0.3s;
}

.team-member .main-content ul li a:hover {
  background-color: var(--bg-color-primary);
  color: #fff;
}

/* 
---------------------------------------------
Testimonials Style
--------------------------------------------- 
*/

.testimonials {
  position: relative;
  padding: 80px 0px;
  margin-top: 150px;
}

.testimonials:before {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: -1;
  width: 65%;
  height: 100%;
  background-color: var(--bg-color-secondary);
  content: "";
  border-top-left-radius: 500px;
  border-bottom-left-radius: 500px;
}

.testimonials .section-heading {
  margin-bottom: 0px;
  margin-left: 60px;
  vertical-align: middle;
}

.testimonials .section-heading p {
  margin-top: 50px;
}

.testimonials .item {
  background-color: var(--bg-color-primary);
  border-radius: 25px;
  padding: 80px;
}

.testimonials .item p {
  font-size: 18px;
  line-height: 40px;
  color: #fff;
  font-style: italic;
  font-weight: 300;
  margin-bottom: 40px;
}

.testimonials .item img {
  border-radius: 50%;
  max-width: 100px;
  float: left;
  margin-right: 25px;
}

.testimonials .item span {
  display: inline-block;
  margin-top: 20px;
  font-size: 15px;
  color: #fff;
}

.testimonials .item h4 {
  color: #fff;
  font-size: 22px;
  font-weight: 600;
  margin-top: 8px;
}

.testimonials .owl-nav {
  position: absolute;
  top: 50%;
  transform: translateY(-5px);
  right: -25px;
  text-align: right;
}

.testimonials .owl-nav .owl-prev i,
.testimonials .owl-nav .owl-next i {
  width: 50px;
  height: 50px;
  line-height: 50px;
  font-size: 24px;
  display: inline-block;
  color: var(--primary-color);
  background-color: #fff;
  border-radius: 50%;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  opacity: 1;
  transition: all 0.3s;
}

.testimonials .owl-nav .owl-prev i {
  position: absolute;
  bottom: 65px;
}

.testimonials .owl-nav .owl-prev i:hover,
.testimonials .owl-nav .owl-next i:hover {
  opacity: 1;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
}

/* 
---------------------------------------------
Events Style
--------------------------------------------- 
*/

.events .section-heading {
  margin-bottom: 100px;
}

.events .item {
  background-color: var(--bg-color-secondary);
  border-radius: 25px;
  position: relative;
  padding: 40px;
  margin-bottom: 96px;
}

.events .item .image {
  position: relative;
}
.events .item .image img {
  position: absolute;
  top: -70px;
  border-radius: 25px;
  object-fit: cover;
  object-position: center;
  width: 260px;
  height: 200px;
  left: 0;
}

.events .item .event-details-list li {
  display: inline-block;
  width: 17.5%;
  vertical-align: middle;
}

.events .item .event-details-list li:first-child {
  width: 35%;
}

.events .item .event-details-list li:nth-of-type(2) {
  width: 28%;
}

.events .item .event-details-list li span.category,
.event-modal-body span.category {
  font-size: 14px;
  text-transform: uppercase;
  color: var(--primary-color);
  background-color: #fff;
  padding: 8px 20px;
  border-radius: 25px;
  font-weight: 500;
  display: inline-block;
  margin-bottom: 20px;
}

.events .item .event-details-list li h4,
.event-modal-body h4 {
  font-size: 22px;
  font-weight: 600;
}

.events .item .event-details-list li span,
.event-modal-body span {
  display: inline-block;
  font-size: 14px;
  color: #4a4a4a;
  margin-bottom: 10px;
}

.events .item .event-details-list li h6,
.event-modal-body h6 {
  font-size: 16px;
  color: var(--primary-color);
  font-weight: 600;
}

.events .item a {
  position: absolute;
  right: 0;
  top: 22px;
  background-color: var(--bg-color-primary);
  width: 60px;
  height: 120px;
  display: inline-block;
  text-align: center;
  line-height: 120px;
  font-size: 18px;
  z-index: 1;
  color: #fff;
  border-radius: 60px 0px 0px 60px;
}
.event-modal-header {
  background-color: var(--bg-color-primary);
}
.event-modal-header h5,
.event-modal-header button {
  color: #fff;
  font-weight: normal;
}
.event-modal-body {
  background-color: var(--bg-color-secondary);
}
.event-modal-footer {
  background-color: var(--bg-color-primary);
}

/* .events .item .event-agenda-list li {
} */
/* 
---------------------------------------------
Contact Style
--------------------------------------------- 
*/

.contact-us {
  margin-top: 80px;
  position: relative;
  padding: 50px 0px;
}

.contact-us:before {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  width: 65%;
  height: 100%;
  background-color: #f2c5fa;
  content: "";
  border-top-right-radius: 500px;
  border-bottom-right-radius: 500px;
}

.contact-us .section-heading {
  margin-right: 110px;
  margin-bottom: 0px;
}

.contact-us .section-heading p {
  margin-top: 50px;
}

.contact-us .special-offer {
  margin-top: 50px;
  background-color: #fff;
  border-radius: 25px;
  padding: 30px 120px;
  display: inline-block;
  position: relative;
}

.contact-us .special-offer span.offer {
  position: absolute;
  left: 0;
  top: 0;
  background-color: #853f91;
  width: 80px;
  padding: 30px 8px;
  line-height: 25px;
  height: 120px;
  border-radius: 15px 60px 60px 15px;
  display: inline-block;
  text-align: center;
  color: #fff;
  font-size: 16px;
  text-transform: uppercase;
  text-align: left;
}

.contact-us .special-offer span.offer em {
  font-size: 28px;
  font-style: normal;
  font-weight: 600;
}

.contact-us .special-offer h6 {
  font-size: 15px;
  color: #4a4a4a;
  text-transform: uppercase;
  font-weight: 400;
  margin-bottom: 15px;
}

.contact-us .special-offer h6 em {
  font-style: normal;
  color: #853f91;
  font-weight: 600;
}

.contact-us .special-offer h4 {
  font-size: 22px;
  font-weight: 600;
}

.contact-us .special-offer h4 em {
  font-style: normal;
  color: #853f91;
}

.contact-us .special-offer a {
  position: absolute;
  right: 30px;
  top: 35px;
  width: 50px;
  height: 50px;
  display: inline-block;
  text-align: center;
  line-height: 50px;
  background-color: #853f91;
  color: #fff;
  border-radius: 50%;
}

.contact-us .contact-us-content {
  border-radius: 25px;
  padding: 80px;
  background-color: #853f91;
  position: relative;
  z-index: 1;
}

.contact-us .contact-us-content::before {
  background: url(./images/contact-dec-01.png);
  position: absolute;
  left: 50%;
  transform: translateX(-149px);
  opacity: 0.5;
  top: 0;
  width: 318px;
  height: 119px;
  content: "";
  z-index: 2;
}

.contact-us .contact-us-content::after {
  background: url(./images/contact-dec-02.png);
  position: absolute;
  right: 0;
  bottom: 0;
  width: 183px;
  height: 149px;
  content: "";
  z-index: 2;
}

#contact-form input {
  width: 100%;
  height: 50px;
  border-radius: 25px;
  background-color: rgba(249, 235, 255, 0.15);
  border: none;
  outline: none;
  font-weight: 300;
  padding: 0px 20px;
  font-size: 14px;
  color: #fff;
  margin-bottom: 30px;
  position: relative;
  z-index: 3;
}

#contact-form input::placeholder {
  color: #fff;
}

#contact-form textarea {
  width: 100%;
  height: 120px;
  border-radius: 25px;
  background-color: rgba(249, 235, 255, 0.15);
  border: none;
  outline: none;
  font-weight: 300;
  padding: 20px;
  font-size: 14px;
  color: #fff;
  margin-bottom: 30px;
  position: relative;
  z-index: 3;
}

#contact-form textarea::placeholder {
  color: #fff;
}

#contact-form button {
  border: none;
  height: 50px;
  font-size: 14px;
  font-weight: 600;
  background-color: #fff;
  padding: 0px 25px;
  border-radius: 25px;
  color: var(--primary-color);
  transition: all 0.4s;
  position: relative;
  z-index: 3;
}

#contact-form button:hover {
  opacity: 0.8;
}

.contact-us-content .more-info {
  text-align: center;
  background: rgb(85, 0, 227);
  background: linear-gradient(
    90deg,
    rgba(85, 0, 227, 1) 0%,
    rgba(198, 61, 255, 1) 100%
  );
  border-radius: 0px 0px 23px 23px;
  padding: 45px 30px 15px 30px;
}

.contact-us-content .more-info .info-item {
  text-align: center;
  margin-bottom: 30px;
}

.contact-us-content .more-info i {
  font-size: 32px;
  color: #fff;
  margin-bottom: 15px;
}

.contact-us-content .more-info h4 a {
  color: #fff;
  font-size: 16px;
  font-weight: 400;
}

/* 
---------------------------------------------
Footer Style
--------------------------------------------- 
*/

footer {
  position: static;
  bottom: 0;
  left: 0;
  right: 0;
  margin-top: 150px;
  background-color: var(--bg-color-primary);
  border-radius: 150px 150px 0px 0px;
  z-index: 1;
}

footer p {
  text-align: center;
  color: #fff;
  font-weight: 400;
}

footer p a {
  color: #fff;
  transition: all 0.3s;
  position: relative;
  z-index: 3;
}

footer p a:hover {
  opacity: 0.75;
}

/* 
footer::before {
  background: url(../images/contact-dec-01.png);
  position: absolute;
  left: 30px;
  transform: rotate(180deg);
  opacity: 1;
  bottom: 0;
  width: 318px;
  height: 119px;
  content: "";
  z-index: 2;
} */

/* 
---------------------------------------------
Responsive Style
--------------------------------------------- 
*/

body {
  overflow-x: hidden;
}

@media (max-width: 576px) {
  .full-calender {
    font-size: 0.6rem;
  }
}
@media (max-width: 767px) {
  .header-area {
    top: 0px;
  }
  .header-area .main-nav .logo h1 {
    border-right: none;
  }
  /* .main-banner .item {
    padding: 80px 30px 120px 30px !important;
    margin-left: 30px;
  }
  .main-banner .owl-nav {
    left: 30px !important;
  } */
  .event_filter {
    padding: 15px 20px;
  }
  .event_filter li {
    display: inline-block;
    margin: 0px 10px;
  }
}

@media (max-width: 992px) {
  .header-area .main-nav #search input,
  .header-area .main-nav #search i {
    display: none;
  }
  .header-area .main-nav .logo h1 {
    font-size: 34px;
  }
  .header-area .main-nav .logo {
    border-right: none;
  }
  .main-banner {
    padding-top: 100px;
  }
  .main-banner .item {
    padding: 100px 60px 140px 60px;
    margin-left: 30px;
    text-align: center;
  }
  .main-banner .item h2,
  .main-banner .item p {
    width: 100%;
  }
  .main-banner .item h2 {
    font-size: 36px;
    line-height: 50px;
  }
  .main-banner .item .buttons {
    display: inline-block;
    text-align: center;
  }
  .main-banner .item .buttons .main-button {
    margin-right: 0px;
    margin-bottom: 30px;
  }
  .main-banner .owl-nav {
    left: 50% !important;
    transform: translateX(-60px) !important;
    bottom: 60px;
  }
  .main-banner .owl-nav .owl-prev i {
    bottom: 0px;
  }
  .main-banner .owl-nav .owl-next i {
    bottom: 0px;
    position: absolute;
    left: 65px;
  }
  .blog-section.section {
    margin-top: 80px;
  }
  .profile-section.section {
    margin-top: 80px;
  }
  .services {
    margin-top: 80px;
  }
  .about-us {
    margin-top: 100px;
  }
  .accordion {
    padding: 40px 25px 10px 25px;
  }
  .services .service-item .icon {
    width: 170px;
    height: 170px;
    line-height: 170px;
  }
  .about-us .section-heading {
    margin-left: 0px;
    margin-top: 120px;
  }
  .about-us::before,
  .testimonials::before,
  .contact-us::before {
    display: none;
  }
  .about-us .section-heading p,
  .testimonials .section-heading p,
  .contact-us .section-heading p {
    margin-top: 30px;
  }
  .about-us .section-heading .main-button {
    margin-top: 30px;
  }
  .about-us .main-button a {
    background-color: var(--bg-color-primary);
    color: #fff;
  }
  .courses {
    margin-top: 100px;
  }
  .event_filter ul li {
    display: block;
    margin: 12px 5px;
  }
  .fun-facts {
    margin-top: 70px;
  }
  .fun-facts::before {
    width: 95%;
  }
  .team {
    margin-top: 160px;
  }
  .team-member {
    margin-bottom: 140px;
  }
  .testimonials {
    margin-top: -50px;
    padding: 0px;
  }
  .testimonials .section-heading {
    margin-left: 0px;
    margin-top: 40px;
  }
  .testimonials .item {
    padding: 40px 40px 60px 40px;
  }
  .testimonials .item h4 {
    font-size: 17px;
  }
  .testimonials .item img {
    margin-right: 15px;
  }
  .testimonials .owl-nav {
    display: none;
  }
  .events {
    margin-top: 80px;
  }
  .events .item {
    margin-bottom: 66px;
  }
  .events .item .image img {
    position: relative;
    max-width: 100%;
  }
  .events .item .event-details-list {
    margin-top: -40px;
  }
  .events .item .event-details-list li:first-child {
    width: 100% !important;
    margin-bottom: 30px;
    padding-bottom: 30px;
    border-bottom: 1px solid #ddd;
  }
  .events .item .event-details-list li:nth-child(2) {
    width: 40% !important;
  }
  .events .item .event-details-list li {
    display: inline-block;
    width: 28% !important;
  }

  .events .item .event-details-list li:last-child {
    text-align: right;
  }
  .events .item a {
    bottom: 150px;
    top: auto;
  }
  .events .item .event-details-list li h6 {
    font-size: 14px;
  }
  .contact-us {
    padding-top: 20px;
  }
  .contact-us .section-heading {
    margin-right: 0px;
  }
  .contact-us .special-offer {
    background-color: var(--bg-color-secondary);
    margin-bottom: 60px;
    padding: 34px 30px 34px 100px;
    width: 100%;
  }
  .contact-us .special-offer h4 {
    font-size: 17px;
  }
  .contact-us .special-offer a {
    width: 40px;
    height: 40px;
    line-height: 40px;
    top: 15px;
    right: 15px;
  }
  .contact-us .contact-us-content {
    padding: 30px;
  }
  footer {
    margin-top: 30px;
  }

  .seo-section.section {
    margin-top: 80px;
  }

  .admin-section.section {
    margin-top: 80px;
  }
}

@media (max-width: 1200px) {
  .header-area .main-nav #search input {
    width: 200px;
  }
  .header-area .main-nav .nav li.has-sub:after {
    right: 15px;
  }
  .fun-facts:before {
    width: 95%;
  }
}

.scmedia {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.links-grids {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  margin-top: 20px;
}

.link-item {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.title-button {
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(205, 11, 11, 0.2);
  backdrop-filter: blur(10px);
  border-radius: 25px;
  color: #853f91;
  padding: 10px 20px;
  font-size: 16px;
  margin-bottom: 10px;
  cursor: pointer;
  transition: transform 0.2s, border 0.2s;
}

.title-button:hover {
  transform: scale(1.05);
  backdrop-filter: blur(10px);
  background: rgba(255, 255, 255, 0.1);
}

.title-button::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 25px;
  background: linear-gradient(45deg, #ff6b6b, #f9d423);
  z-index: -1;
  padding: 2px;
  -webkit-mask: 
    linear-gradient(#fff 0 0) content-box, 
    linear-gradient(#fff 0 0);
  mask: 
    linear-gradient(#fff 0 0) content-box, 
    linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
}

/* Add these styles to Header.css */


/* Custom styles for FullCalendar month title */
.fc-toolbar-title {
  color: #853f91 !important;
}

/* Add this CSS to your stylesheet */
.p-multiselect {
  width: 100%;
}

.p-multiselect-panel {
  z-index: 1050; /* Ensure the dropdown is above other content */
}

.p-multiselect-item {
  display: flex;
  align-items: center;
}

.p-multiselect .p-checkbox-box {
  margin-right: 8px;
}

/* Add this CSS to your stylesheet */
.checkdrop .multiselect {
  border: none !important; /* Remove the internal border */
}

.checkdrop .multiselect__input {
  border: none !important; /* Remove the input border */
}

.checkdrop .multiselect__single, .checkdrop .multiselect__option {
  border: none !important; /* Remove border for options */
}


.checkdrop {
  border: 1px solid #ccc; /* Set the desired border for the container */
  border-radius: 4px; /* Optional: Add border radius for rounded corners */
}

