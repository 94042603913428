.nav-pills .nav-link.active {
    background-color: #853f91 !important; 
    color: white !important;
  }
  
  .custom-list {
    list-style: none;
    padding: 0;
  }
  
  .custom-list li {
    position: relative;
    padding-left: 20px; /* Adjust the padding to make space for the dot */
    margin-bottom: 5px;
  }
  
  .custom-list li::before {
    content: '•'; /* Unicode character for a dot */
    position: absolute;
    left: 0;
    color: #853f91; /* Same color as your headings */
    font-size: 20px; /* Adjust size as needed */
    line-height: 1;
  }
  
  @media (max-width: 768px) {
    .img-fluid{
      padding-right: 40px;
    }
  }