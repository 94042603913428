@import url(https://fonts.googleapis.com/css?family=Montserrat);
@font-face {
  font-family: 'bebas_neueregular';
  src: url("https://dl.dropboxusercontent.com/u/81135676/bebasneue_regular-webfont.eot");
  src: url("https://dl.dropboxusercontent.com/u/81135676/bebasneue_regular-webfont.woff2") format("woff2"), url("https://dl.dropboxusercontent.com/u/81135676/bebasneue_regular-webfont.woff") format("woff"), url("https://dl.dropboxusercontent.com/u/81135676/bebasneue_regular-webfont.ttf") format("truetype"), url("https://dl.dropboxusercontent.com/u/81135676/bebasneue_regular-webfont.svg#bebas_neueregular") format("svg");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'bebas_neuebold';
  src: url("https://dl.dropboxusercontent.com/u/81135676/bebasneue_bold-webfont.eot");
  src: url("https://dl.dropboxusercontent.com/u/81135676/bebasneue_bold-webfont.woff2") format("woff2"), url("https://dl.dropboxusercontent.com/u/81135676/bebasneue_bold-webfont.woff") format("woff"), url("https://dl.dropboxusercontent.com/u/81135676/bebasneue_bold-webfont.ttf") format("truetype"), url("https://dl.dropboxusercontent.com/u/81135676/bebasneue_bold-webfont.svg#bebas_neuebold") format("svg");
  font-weight: normal;
  font-style: normal;
}

body, html {
  display: block;
  padding: 0;
  margin: 0;
  width: 100%;
  position: relative;
  height: 100%;
}

body {
  font-family: "bebas_neuebold", "Arial", sans-serif;
}

section {
  position: fixed;
  width: 100%;
  height: 100%;
  background: radial-gradient(circle, #ad45ac, #3e3c3c);
}


#beerCanvas {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
}


.coming_content h1 {
  font-size: 20px;
  margin-top: 200px;
  letter-spacing: 2px;
  text-align: center;
  color: white;
}
.coming_content .separator_container {
  width: 100%;
  display: block;
  text-align: center;
  position: relative;
  margin: 12px 0;
}


.coming_content .separator_container:before, .coming_content .separator_container:after {
  display: table;
  content: "";
}
.coming_content .separator_container:after {
  clear: both;
}
.coming_content .separator {
  color: white;
  margin: 0 auto 1em;
  width: 11em;
}
.coming_content .line_separator svg {
  width: 30px;
  height: 20px;
}

.coming_content .line_separator:before, .coming_content .line_separator:after {
  display: block;
  width: 40%;
  content: " ";
  margin-top: 14px;
  border: 1px solid white;
}
.coming_content .line_separator:before {
  float: left;
}
.coming_content .line_separator:after {
  float: right;
}
.coming_content h3 {
  font-family: "Montserrat", sans-serif;
  letter-spacing: 2px;
  line-height: 2;
  font-size: 50px;
  font-weight: 400;
  text-align: center;
  margin: 0;
  color: white;
  text-transform: uppercase;
}

.coming_content h3 a {
  text-decoration: underline;
}

.summary-container {
  background-color: #f0f0f0;
  padding: 20px;
  border-radius: 10px;
  width: 50%;
  margin: 0 auto;
}

.summary-item {
  margin-bottom: 10px;
  font-size: 18px;
}

.summary-item.total {
  font-weight: bold;
  font-size: 20px;
}


.alert-danger {
  color: red !important;
}

.alert-success{
  color: blue !important;
  background-color: white !important;
  border-color: white !important;
}