/* .earning-deduction-container {
    margin-bottom: 20px;
  }
  
  .form-group {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 20px;
  }
  
  .form-item {
    flex: 1;
  }
  
  label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
  }
  
  input {
    padding: 8px;
    font-size: 16px;
    width: 100%;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .add-button {
    padding: 8px 16px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .add-button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
  
  .table-container {
    overflow-x: auto;
  }
  
  table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
  }
  
  th, td {
    padding: 10px;
    border: 1px solid #ccc;
    text-align: left;
  }
  
  th {
    background-color: #f2f2f2;
  }
  
  button {
    padding: 5px 10px;
    background-color: #dc3545;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  button:hover {
    background-color: #c82333;
  }
   */

   @media (max-width: 768px) {
    .form-grouppayslip .form-itempayslip input{
      width:100px;
    }
   }