table{
    width:100%;
    overflow-x:auto;
    border-radius: 10px;
    font-family: 'Roboto', sans-serif;
  }
  #listPatients1 .tbl-header{
    background-color: rgba(133, 63, 145);
   }
  .tbl-content{
    
    overflow-x:auto;
    margin-top: 0px;
    border: 1px solid rgba(242, 197, 250);
  }
  th{
    padding: 20px 15px;
    text-align: left;
    font-weight: 500;
    font-size: 12px;
    color: white;
    text-transform: uppercase;
  }
  td{
    padding: 15px;
    text-align: left;
    vertical-align:middle;
    font-weight: 300;
    font-size: 14px;
    color: black;
    border-bottom: solid 1px rgba(242, 197, 250);
  }
  
  .allpaymentadmin{
    margin-top: 200px;
  }

  .allpaymentadmin h1{
    color: #853f91;
  }

  .allpaymentadmin label{
    color: #853f91;
  }

  table#listPatients1 th,
table#listPatients1 td {
    padding: 8px 12px; 
    text-align: center;
}

table#listPatients1 th:first-child,
table#listPatients1 td:first-child {
    width: 50px; 
}

table#listPatients1 th:nth-child(2),
table#listPatients1 td:nth-child(2) {
    width: 150px;
}

.toggle-btn {
    padding: 5px 10px;
    width: 50px;
}

.toggle-btn.No {
    background-color: #e74c3c;
    color: white;
}

.patpay{
  margin-left: 100px;
}