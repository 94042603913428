.payment_section span{
    color: red;
}

.payment_section .Paymentp{
    font-weight: bold;
}

.payment_section .PaymentButton{
    width: 200px;
}

.paymentline{
    text-decoration: underline;
    text-decoration-color: blue;
}