.header {
  background-color: white;
  padding: 1rem;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.header-area {
  box-shadow: 0 2px 4px #853f91;
}


.header-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
}

.title h1 {
  color: #853f91;
  margin: 0;
  font-size: 2rem;
}

.header nav ul {
  list-style-type: none;
  padding: 0;
  display: flex;
  margin: 0;
}

.header nav ul li {
  margin: 0 1rem;
}

.header nav ul li a {
  color: #853f91;
  text-decoration: none;
}

.header nav ul li a:hover {
  text-decoration: underline;
}

.animated-title {
  color: #dbd56e;
  background: -webkit-linear-gradient(30deg, #f50fa9, #f2754b);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 3rem;
  font-weight: 500;
  overflow: hidden;
  white-space: nowrap;
 
  animation: type 10s steps(40) infinite alternate, cursor 4s step-end infinite;
  position: relative;
}

@keyframes type {
  0% {
    width: 0;
  }
  50% {
    width: 100%;
  }
  100% {
    width: 0;
  }
}

@keyframes cursor {
  50% {
    border-color: transparent;
  }
}



@import url('https://fonts.googleapis.com/css?family=Raleway:400,500,800');
.nav {
  font-family: 'Raleway', Arial, sans-serif;
  text-align: center;
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: 1px;
}

.nav * {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: all 0.35s ease;
  transition: all 0.35s ease;
}

.nav li {
  display: inline-block;
  list-style: outside none none;
  margin: 0.5em 1.5em;
  padding: 0;
}

.nav a {
  padding: 0.3em 0;
  color: rgba(255, 255, 255, 0.5);
  position: relative;
  text-decoration: none;
  display: inline-block;
}

.nav li a:before,
.nav li a:after {
  height: 3px;
  position: absolute;
  content: '';
  -webkit-transition: all 0.35s ease;
  transition: all 0.35s ease;
  background-color: #853f91;
  width: 0;
}


.nav a:before {
  top: 0;
  left: 0;
}

.nav a:after {
  bottom: 0;
  right: 0;
}

.nav a:hover,
.nav .current a {
  color: #ffffff;
}

.nav a:hover:before,
.nav .current a:before,
.nav a:hover:after,
.nav .current a:after {
  width: 100%;
}

.nav .dropdown {
  display: inline-block;
  list-style: outside none none;
  margin: 0.5em 1.5em;
  padding: 0;
}

.nav .dropdown1 {
  display: inline-block;
  list-style: outside none none;
  margin: 0.5em 1.5em;
  padding: 0;
}



@media (max-width: 767px) {
  .nav li{
    margin: 0.5em 0;
  }

  .nav .dropdown1 {
    border-color: #f2c5fa;
    
  }

}

@media only screen and (max-width: 768px) {
  .icoimage {
    width: 40px !important; /* Adjust the width as needed */
    position:fixed;
    
  }

  .animated-title{
    padding-left: 20px;
  }
}


@media only screen and (max-width: 767px) {
  .header-area .menu-trigger {
    display: block;
    cursor: pointer;
    background-color: #853f91;
    padding: 10px;
    color: white;
    text-align: center;
  }

  .header-area .main-nav .nav {
    display: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #853f91;
  z-index: 1000;
  text-align: center;
  }

  .dropdown1 .dropdown-menu {
    position: fixed;
    top : 42%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #f2c5fa;
    border: 1px solid #f2c5fa;
    max-height: 500px;
    text-align: center;
    display: none;
    width: 90%; /* Adjust width as needed */
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }

  .dropdown1.show .dropdown-menu {
    display: block;
  }

  .dropdown .dropdown-menu{
    position: fixed;
    top: 70%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #f2c5fa;
    border: 1px solid #f2c5fa;
    max-height: 500px;
    text-align: center;
    display: none;
    width: 90%; /* Adjust width as needed */
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
}

