.links-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    padding: 20px;
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .link-item {
    background: #f8f8f8;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    text-align: center;
    position: relative;
  }
  
  .link-item a {
    color: #853f91;
    text-decoration: none;
    font-weight: bold;
  }
  
  .link-item a:hover {
    text-decoration: underline;
  }


@media (max-width: 768px) {
  .links-grids {
    grid-template-columns: 1fr;
  }
}