.achievement-section {
  display:flex;
  align-items: center;
  margin: 20px 0;
}

.achievement-image img {
  width: 300px; /* Adjust the width as needed */
  height: 400px;
  border-radius: 150px;
  object-fit: cover;
  margin-left: 250px;

}

.achievement-content {
  margin-left: 40px;
  max-width: 600px; /* Adjust the width as needed */
}

.achievement-content h2 {
  margin: 0 0 10px;
  font-size: 24px;
  color:#853f91;
}

.achievement-content p {
  margin: 0 0 10px;
  font-size: 16px;
  color: #555;
}

.achievement-content ul {
  list-style-type: none;
  padding: 0;
}

.achievement-content li {
  margin: 10px 0;
  font-size: 16px;
  color: #555;
}

.star-icon {
  color: #853f91; /* Adjust the color as needed */
  margin-right: 10px;
}

/* Media query for mobile view */
@media (max-width: 768px) {
  .achievement-section {
      flex-direction: column;
      align-items: center;
  }

  .achievement-image img {
      margin-left: 0;
      margin-bottom: 20px;
  }

  .achievement-content {
      
      text-align: center;
  }

  .achievement-content p {
    text-align: justify;
  }

  .achievement-content ul {
    text-align: left; /* Center-align the list on mobile */
  }

}