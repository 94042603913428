
/* Achievement.css */
.fun-facts {
	position: relative;
	background: url('images/fun-bg.jpg');
	background-size: cover;
	background-repeat: no-repeat;
	padding: 120px 0;
}

.fun-facts .overlay {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(112, 41, 99, 0.729); /* Blue color with transparency */
	z-index: 1;
}

.fun-facts .container {
	position: relative;
	z-index: 2;
}

.fun-facts .row {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
}

.fun-facts .single-fun {
	display: flex;
	align-items: center;
	text-align: left;
	background: rgba(255, 255, 255, 0.1); /* Optional: light background for better contrast */
	padding: 20px;
	border-radius: 10px;
	margin-bottom: 20px;
	width: 100%;
	max-width: 300px; /* Adjust based on your design */
}

.fun-facts .single-fun .icon {
	font-size: 62px;
	color: white;
	height: 70px;
	width: 70px;
	line-height: 67px;
	text-align: center;
	border: 2px solid #fff;
	border-radius: 50%;
	transition: all 0.4s ease;
	flex-shrink: 0; /* Prevent shrinking */
	margin-right: 20px;
}

.fun-facts .single-fun:hover .icon {
	color: #853f91;
	background: #fff;
	border-color: transparent;
}

.fun-facts .single-fun .content {
	display: flex;
	flex-direction: column;
}

.fun-facts .single-fun .content .counter {
	font-size: 30px;
	font-weight: 600;
	color: #fff;
	
}

.fun-facts .single-fun .content p {
	font-size: 15px;
	color: #fff;
	margin: 0;
	
}



/* Add this to your existing CSS file */
@media (max-width: 768px) {
	.fun-facts .single-fun {
	  flex-direction: column; /* Stack icon and text vertically */
	  align-items: center; /* Center align items horizontally */
	  text-align: center; /* Center align text */
	  max-width: 90%; /* Adjust the width for mobile view */
	  margin: 10px auto; /* Center align the single fun facts divs */
	}

	.fun-facts .single-fun .icon {
	  font-size: 50px; /* Adjust icon size if needed */
	  height: 60px;
	  width: 60px;
	  line-height: 60px;
	  margin-right: 0; /* Remove margin-right on mobile */
	  margin-bottom: 10px; /* Add space below icon */
	}

	.fun-facts .single-fun .content {
	  text-align: center; /* Center align the content */
	}

	.fun-facts .single-fun .content .counter {
	  font-size: 24px; /* Adjust counter font size if needed */
	}

	.fun-facts .single-fun .content p {
		
	  font-size: 14px; /* Adjust text font size if needed */
	}
}

