.breadcrumbs{
    position: relative;
	background: url('images/Componentback.jpg');
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
	padding: 120px 0;
}

.breadcrumbs .overlay {
    position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(188, 4, 255, 0.26); /* Blue color with transparency */
	z-index: 1;
}