.grid-containerpayslip {
  
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
    margin-bottom: 20px;
    width: 800px;
  }
  
  .grid-itempayslip {
    display: flex;
    flex-direction: column;
  }
  
  label {
    font-weight: bold;
    margin-bottom: 5px;
  }
  
  input {
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  @media (max-width: 768px) {
    .grid-containerpayslip {
      display: flex;
     
      flex-direction: column;
      width: 300px;
      margin-left: 40px;
    }

    .mobileemployeehone{
      text-align: center;
    }
  }