.alert {
    position: fixed;
    z-index: 1000;
    padding: 16px;
    margin: 0;
    border-radius: 4px;
    color: #fff;
    display: none;
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
  }
  
  .alert.show {
    display: block;
    opacity: 1;
  }
  
  .alert-close {
    margin-left: 15px;
    color: white;
    font-weight: bold;
    float: right;
    font-size: 22px;
    line-height: 20px;
    cursor: pointer;
  }
  
  .alert-close:hover {
    color: black;
  }
  
  .alert.success {
    background-color: #4CAF50;
  }
  
  .alert.error {
    background-color: #f44336;
  }
  
  .alert.warning {
    background-color: #ff9800;
  }
  
  .alert.info {
    background-color: #2196F3;
  }
  