/* Calendar.css */


  
  @media (max-width: 767px) {
    .fc-header-toolbar {
        flex-direction: row;
        
      align-items: center;
    }
  
    .fc-toolbar-chunk.fc-center {
      order: -1;
      margin-bottom: 10px;
    }

    .fc-toolbar-chunk {
        margin-bottom: 10px 0; /* Add space between each chunk */
      }

    .checkdrop{
        width:200px;
    }

    .time-select-container{
       flex-direction: row;
    }
  }

  @media (max-width: 576px) {
    .flex-row-mobile {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      gap: 10px;
    }

    .submitdoct-atte{
        margin-left: 120px;
    }
  }
  
  .flex-row-mobile{
   
    display: flex;
    flex-direction: row;
  }

  