

.container1 {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 1rem;
}

.row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.service-item1 {
  background-color: #f2c5fa;
  color: white;
  padding: 2rem;
  margin: 1rem;
  border-radius: 0.5rem;
  text-align: left;
  flex: 1;
  position: relative;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 300px;
  box-sizing: border-box;
}

.service-item1 .icon {
  position: absolute;
  top: -40px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #853f91;
  border-radius: 50%;
  width: 80px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 0.3s, width 0.3s, height 0.3s;
}

.service-item1 .icon:hover {
  transform: translateX(-50%) translateY(-10px);
  width: 90px;
  height: 90px;
}

.service-item1 .icon i {
  font-size: 2rem;
  color: white;
  transition: transform 0.3s;
}

.service-item1 .main-content {
  margin-top: 40px;
}

.service-item1 h4 {
  font-size: 1.5rem;
  margin-top: 1rem;
  color: #853f91;
}

.service-item1 p {
  color: black;
}

.service-item1 .main-button a {
  background-color: #853f91;
  color: white;

  text-decoration: none;
  border-radius: 20px;
  display: inline-block;
  margin-top: 1rem;
}

.service-item1 .main-button a:hover {
  background-color: #00796b;
}

