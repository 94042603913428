.footer {
  background-color: #c99ef0; 
  /* background: linear-gradient(315deg, #AD1DEB  0%,  #6E72FC 74%); */
  color: black;
  text-align: center;
  padding: 1rem;
  position: relative;
  width: 100%;
  bottom: 0;
}
.footer .footer-item a {
  line-height: 35px;
  color: var(--bs-body);
  transition: 0.5s;
}

.footer .footer-item p {
  line-height: 35px;
  color: black;
}

.footer .footer-item a:hover {
  letter-spacing: 2px;
  color: #f2c5fa;
}


.clip {
border: 0;
clip: rect(0 0 0 0);
height: 1px;
margin: -1px;
overflow: hidden;
padding: 0;
position: absolute;
width: 1px;
}

.svg--source {
display: none
}

.svg--icon {
width: 100%;
max-width: 5rem;
height: 100%;
max-height: 5rem;
display: block;
margin: 0 auto;
fill: currentColor
}

.wrapper {
width: 100%;



flex-wrap: wrap;
align-items: center;
justify-content: center;
padding: 1rem;
}

.share {
width: 3rem;
height: 3rem;
float: left;
margin: .5rem 1rem .5rem 0;
color: #353c4a;
border: .125rem solid #f3f3f3;
box-shadow: 0 0 8px 0 rgba(50, 50, 50, 0.15);
border-radius: 50%;
transition: 250ms;

&:last-child {
  margin-right: 0
}

&:hover,
&:focus {
  //background: #f1f1f1;
}

&:focus {
  outline-color: inherit;
}
}

.twitter {
&:hover,
&:focus {
  color: #00ACED;
  box-shadow: 0 0 24px 0 #00ACED
}
}

.github {
&:hover,
&:focus {
  color: #4183c4;
  box-shadow: 0 0 24px 0 #4183c4
}
}

.pinterest {
&:hover,
&:focus {
  color: #bd081c;
  box-shadow: 0 0 24px 0 #bd081c
}
}

.youtube {
&:hover,
&:focus {
  color: #cd201f;
  box-shadow: 0 0 24px 0 #cd201f
}
}

.facebook {
&:hover,
&:focus {
  color: #3b5998;
  box-shadow: 0 0 24px 0 #3b5998
}
}

.google {
&:hover,
&:focus {
  color: #dd4b39;
  box-shadow: 0 0 24px 0 #dd4b39
}
}

.footer-item {
display: flex;
flex-direction: column;
}

.footer-link {
margin-left: 100px;
display:flex;
align-items: center;
color: #000; /* Adjust this color as needed */
text-decoration: none;
margin-bottom: 8px; /* Space between links */
}

.footer-link i {
margin-right: 8px; /* Space between icon and text */
}

.footer-link:hover {
text-decoration: underline; /* Optional: Underline on hover */
}

/* Adjust the heading style */
.footer-item h5 {
font-weight: bold;
color: #fff; /* Adjust this color as needed */
}

.footer{
font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

@media (max-width: 768px) {
.wrapper .connect {
  margin-left: 35px;
}

}
