 .gallery {
    --g: 8px;   /* the gap */
    --s: 300px; /* the size */
    
    display: grid;
    border-radius: 50%;
    margin-left: 100px;
    margin-top: 50px;
  }
 .gallery > img {
    grid-area: 1/1;
    width: 400px;
    aspect-ratio: 1;
    object-fit: cover;
    border-radius: 50%;
    transform: translate(var(--_x,0),var(--_y,0));
    cursor: pointer;
    z-index: 0;
    transition: .3s, z-index 0s .3s;
  }
 .gallery img:hover {
    --_i: 1;
    z-index: 1;
    transition: transform .2s, clip-path .3s .2s, z-index 0s;
  }
.gallery:hover img {
    transform: translate(0,0);
  }
  .gallery > img:nth-child(1) {
    clip-path: polygon(50% 50%,calc(50%*var(--_i,0)) calc(120%*var(--_i,0)),0 calc(100%*var(--_i,0)),0 0,100% 0,100% calc(100%*var(--_i,0)),calc(100% - 50%*var(--_i,0)) calc(120%*var(--_i,0)));
    --_y: calc(-1*var(--g))
  }
 .gallery > img:nth-child(2) {
    clip-path: polygon(50% 50%,calc(100% - 120%*var(--_i,0)) calc(50%*var(--_i,0)),calc(100% - 100%*var(--_i,0)) 0,100% 0,100% 100%,calc(100% - 100%*var(--_i,0)) 100%,calc(100% - 120%*var(--_i,0)) calc(100% - 50%*var(--_i,0)));
    --_x: var(--g)
  }
 .gallery > img:nth-child(3) {
    clip-path: polygon(50% 50%,calc(100% - 50%*var(--_i,0)) calc(100% - 120%*var(--_i,0)),100% calc(100% - 120%*var(--_i,0)),100% 100%,0 100%,0 calc(100% - 100%*var(--_i,0)),calc(50%*var(--_i,0)) calc(100% - 120%*var(--_i,0)));
    --_y: var(--g)
  }
  .gallery > img:nth-child(4) {
    clip-path: polygon(50% 50%,calc(120%*var(--_i,0)) calc(50%*var(--_i,0)),calc(100%*var(--_i,0)) 0,0 0,0 100%,calc(100%*var(--_i,0)) 100%,calc(120%*var(--_i,0)) calc(100% - 50%*var(--_i,0)));
    --_x: calc(-1*var(--g))
  }
  
.row12 .cards_content{
    width: 300px;
    background-color: #f2c5fa;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 16px;
    margin: 16px;
    text-align: justify;
    transition: box-shadow 0.3s ease-in-out;
    margin-right: 100px;
    height: 300px;
    margin-top: 100px;
    
}



.row12 .cards_content:hover{
    box-shadow:  0 8px 16px rgba(0, 0, 0, 0.2);
}


.row12 {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}