.paymentdetails {
    background-color: #853f91;
    padding: 15px;
    border-radius: 10px;
    overflow: hidden;
    transition: all .7s ease;
    font-family: 'Montserrat', sans-serif;
    position: relative;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 10px;
    align-items: center;
    text-align: center;
}

#paymentsdetails {
    
    display: flex;
    flex-direction: row;
}

.paymentdetails .details-left {
    text-align: left;
}

.paymentdetails .details-center {
    display: flex;
    flex-direction: column;
    align-items: flex-start; /* Align to left */
    justify-content: center; /* Vertically center the content */
    text-align: left;
}

.paymentdetails .details-right {
    text-align: right;
}

.paymentdetails:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 4px -4px rgba(242, 197, 250, .5);
    transition: all .7s ease;
}

.paymentdetails p, .paymentdetails h5 {
    color: white;
    font-size: 14px;
    margin-bottom: 5px;
}

.paymentdetails .timesnap {
    color: white;
}

.paymentdetails h5 {
    font-weight: normal;
    margin: 0;
    font-size: 12px;
}

.bold {
    font-weight: bold;
}

.iconpdetails {
    position: absolute;
    color: white;
    font-size: 100px;
    top: 30px;
    right: -5px;
    opacity: .3;
    z-index: 0;
}

.pagination {
    display: flex;
    justify-content: center;
    padding: 0;
    list-style: none;
  }
  
  .pagination .page-item {
    margin: 0 5px;
  }
  
  .pagination .page-link {
    background-color: #853f91;
    color: white;
    border: none;
    padding: 10px 15px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .pagination .page-link:hover {
    background-color: #6a3274;
  }
  
  .pagination .page-item.active .page-link {
    background-color: #6a3274;
  }
  
  .pagination .page-link:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
  