.section1{
    margin-left: 350px;
    width: 800px;
}

.button-container{
    margin-left: 450px;
}

@media (max-width: 768px) {
    .section1{
        margin-left: 0;
        width: 150px;
    }

    .button-container{
        margin-left: 30%;
    }

  
}

.payleft{
    margin-left: 350px;
    width: 800px;
}

@media (max-width: 576px) {
    .payleft{
        margin-left: 0;
        width: 150px;
    }
}