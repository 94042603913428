 
  .testimony-section {
    position: relative;
    background-color: white;
    padding: 20px;
  }
  .testimony-section .owl-carousel {
    margin: 0;
  }
  
  .testimony-wrap {
    display: block;
    position: relative;
  }
  .testimony-wrap .user-img {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    position: relative;
    margin-top: -75px;
    margin: 0 auto;
  }
  .testimony-wrap .user-img .quote {
    position: absolute;
    bottom: -20px;
    left: 50%;
    width: 40px;
    height: 40px;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    background: #A890FE;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    border-radius: 50%;
  }
  .testimony-wrap .user-img .quote i {
    color: #fff;
  }
  .testimony-wrap .name {
    font-weight: 500;
    font-size: 16px;
    margin-bottom: 0;
    color: #000;
  }
  .testimony-wrap .position {
    font-size: 13px;
  }
  
  .testimony-section .item {
    display: flex;
    flex-direction: row;
  }

  /* .slick-list {
    display: flex !important;
    flex-direction: row !important;
  } */