
/*===========================
	Start Hero Area CSS
=============================*/
.slider .single-slider {
    margin-top: 100px;
	height: 500px;
   background:  url('images/physioraimg.jpg');;
	background-size: cover;
	background-position: 40% 30%;
	background-repeat:no-repeat;
}
.slider .single-slider .text{
    position: static;
    margin-top:120px;
    margin-right: 100px;
}
.slider.index2 .single-slider .text{
	margin-top:150px;
}
.slider .single-slider h1 {
    
    margin-right: 50px;
	color: #2C2D3F;
	font-size: 36px;
	font-weight: 700;
	margin: 0;
	padding: 0;
	line-height: 42px;
}
.slider .single-slider h1 span{
	color:#853f91;
}
.slider .single-slider p {
	color: #2C2D3F;
	margin-top: 27px;
	font-weight: 400;
}
.slider .single-slider .but{
	margin-top:30px;
}
.slider .single-slider .btn{
    margin-top: 30px;
	color:#fff;
	background:#1a76d1;
	font-weight:500;
	display:inline-block;
	margin:0;
	margin-right:10px;
}
.slider .single-slider .btn:last-child{
	margin:0;
}
.slider .single-slider .btn.primary{
	background:#2C2D3F;
	color:#fff;
}
.slider .single-slider .btn.primary:before{
	background:#1A76D1;
}
.slider .owl-carousel .owl-nav {
	margin: 0;
    position: absolute;
    top: 50%;
    width: 100%;
	margin-top:-25px;
}
.slider .owl-carousel .owl-nav div {
	height: 50px;
	width: 50px;
	line-height: 50px;
	text-align: center;
	background: #1A76D1;
	color: #fff;
	font-size: 26px;
	position: absolute;
	margin: 0;
	-webkit-transition: all 0.4s ease;
	-moz-transition: all 0.4s ease;
	transition: all 0.4s ease;
	padding: 0;
	border-radius: 50%;
}
.slider .owl-carousel .owl-nav div:hover{
	background:#2C2D3F;
	color:#fff;
}
.slider .owl-carousel .owl-controls .owl-nav .owl-prev{
	left:20px;
}
.slider .owl-carousel .owl-controls .owl-nav .owl-next{
	right:20px;
}

/* Slider Animation */
.owl-item.active .single-slider h1{
    animation: fadeInUp 1s both 0.6s;
}
.owl-item.active .single-slider p{
    animation: fadeInUp 1s both 1s;
}
.owl-item.active .single-slider .button{
    animation: fadeInDown 1s both 1.5s;
}
/*===========================
	End Hero Area CSS
=============================*/
/*=============================
	Start Schedule CSS
===============================*/
.schedule {
	background: #fff;
	margin-top: 120px;
	padding: 0;
	height: 230px;
}
.schedule .schedule-inner {
	position: relative;
	transform: translateY(-50%);
	z-index:9;
}
.schedule .single-schedule {
	position: relative;
	text-align: left;
	z-index:3;
	border-radius:5px;
	background:#853f91;
	 -webkit-transition: all .3s ease-out 0s;
    -moz-transition: all .3s ease-out 0s;
    -ms-transition: all .3s ease-out 0s;
    -o-transition: all .3s ease-out 0s;
    transition: all .3s ease-out 0s;
}
.schedule .single-schedule .inner {
	overflow:hidden;
	position: relative;
	padding:30px;
	z-index:2;
}
.schedule .single-schedule:before{
	position: absolute;
    z-index: -1;
    content: '';
    bottom: -10px;
    left: 0;
    right: 0;
    margin: 0 auto;
    width: 80%;
    height: 90%;
    background:#853f91;
    opacity: 0;
    filter: blur(10px);
    -webkit-transition: all .3s ease-out 0s;
    -moz-transition: all .3s ease-out 0s;
    -ms-transition: all .3s ease-out 0s;
    -o-transition: all .3s ease-out 0s;
    transition: all .3s ease-out 0s;
}
.schedule .single-schedule:hover:before{
	opacity: 0.8;
}
.schedule .single-schedule:hover{
	transform: translateY(-5px);
}
.schedule .single-schedule .icon i{
	position: absolute;
	font-size: 110px;
	color: #fff;
	 -webkit-transition: all .3s ease-out 0s;
    -moz-transition: all .3s ease-out 0s;
    -ms-transition: all .3s ease-out 0s;
    -o-transition: all .3s ease-out 0s;
    transition: all .3s ease-out 0s;
	z-index:-1;
	visibility:visible;
	opacity:0.2;
	right: -25px;
	bottom: -30px;
}
.schedule .single-schedule span{
	display:block;
	color:#fff;
}
.schedule .single-schedule h4{
	font-size: 20px;
	font-weight:600;
	display:inline-block;
	text-transform:capitalize;
	color:#fff;
	margin-top:13px;
}
.schedule .single-schedule p{
	color:#fff;
	margin-top:22px;
}
.schedule .single-schedule a {
	color: #fff;
	margin-top: 25px;
	font-weight: 500;
	display: inline-block;
	position: relative;
}
.schedule .single-schedule a:before{
	position:absolute;
	content:"";
	left:0;
	bottom:0;
	height:1px;
	width:0%;
	background:#fff;
	-webkit-transition:all 0.4s ease;
	-moz-transition:all 0.4s ease;
	transition:all 0.4s ease;
}
.schedule .single-schedule a:hover:before{
	width:100%;
	width:100%;
}
.schedule .single-schedule a i{
	margin-left:10px;
}
.schedule .single-schedule .time-sidual{
	
}
.schedule .single-schedule .time-sidual{
	overflow:hidden;
	margin-top:17px;
}
.schedule .single-schedule .time-sidual li {
	display: block;
	color: #fff;
	width: 100%;
	margin-bottom:3px;
}
.schedule .single-schedule .time-sidual li:last-child{
	margin:0;
}
.schedule .single-schedule .time-sidual li span{
	display:inline-block;
	float:right;
}
.schedule .single-schedule .day-head .time {
	font-weight: 400;
	float: right;
}

@media (max-width: 767px) {
.schedule{
	margin-top:450px;
}
.slider .single-slider {
	position: sticky;
	height:600px;
}
.middle{
	margin-top: 10px;
}
.last{
	margin-top: 10px;
}

.container-xxl{
	margin-top: 200px;
}
}
/*=============================
	End Schedule CSS
===============================*/
.modal-open {
    overflow: hidden;
  }
  
  .modal {
    position: fixed;
    margin-top: 150px;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1050;
    display: none;
    overflow: hidden;
    outline: 0;
    
  }
  .modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto;
  }
  
  .modal-dialog {
    position: relative;
    width: auto;
    margin: 0.5rem;
    pointer-events: none;
  }
  .modal.fade .modal-dialog {
    -webkit-transition: -webkit-transform 0.3s ease-out;
    transition: -webkit-transform 0.3s ease-out;
    -o-transition: transform 0.3s ease-out;
    transition: transform 0.3s ease-out;
    transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
    -webkit-transform: translate(0, -25%);
    -ms-transform: translate(0, -25%);
    transform: translate(0, -25%);
  }
  @media screen and (prefers-reduced-motion: reduce) {
    .modal.fade .modal-dialog {
      -webkit-transition: none;
      -o-transition: none;
      transition: none;
    }
  }
  .modal.show .modal-dialog {
    -webkit-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    transform: translate(0, 0);
  }
  
  .modal-dialog-centered {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    min-height: calc(100% - (0.5rem * 2));
  }
  
  .modal-content {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    width: 100%;
    pointer-events: auto;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 0.3rem;
    outline: 0;
  }
  
  .modal-backdrop {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1040;
    background-color: #000;
  }
  .modal-backdrop.fade {
    opacity: 0;
  }
  .modal-backdrop.show {
    opacity: 0.5;
  }
  
  .modal-header {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding: 1rem;
    border-bottom: 1px solid #e9ecef;
    border-top-left-radius: 0.3rem;
    border-top-right-radius: 0.3rem;
  }
  .modal-header .close {
    padding: 1rem;
    margin: -1rem -1rem -1rem auto;
	background-color: #f2c5fa;
  }
  
  .modal-title {
    margin-bottom: 0;
    line-height: 1.5;
  }
  
  .modal-body {
	
    position: relative;
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    padding: 1rem;
  }
  
  .modal-footer {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    padding: 1rem;
    border-top: 1px solid #e9ecef;
  }
  .modal-footer > :not(:first-child) {
    margin-left: 0.25rem;
  }
  .modal-footer > :not(:last-child) {
    margin-right: 0.25rem;
  }
  
  .modal-scrollbar-measure {
    position: absolute;
    top: -9999px;
    width: 50px;
    height: 50px;
    overflow: scroll;
  }
  
  @media (min-width: 576px) {
    .modal-dialog {
      max-width: 500px;
      margin: 1.75rem auto;
    }
    .modal-dialog-centered {
      min-height: calc(100% - (1.75rem * 2));
    }
    .modal-sm {
      max-width: 300px;
    }
  }
  
  @media (min-width: 992px) {
    .modal-lg {
      max-width: 800px;
    }
  }
  
  .modal-dialog {
	max-width: 500px; /* Adjust the maximum width of the modal */
  }

  .form-group1 {
	margin-bottom: 7px;
	
  }

  .form-control1 {
	padding: 5px 10px;
	font-size: 14px;
	border-radius: 4px;
	border-color: #853f91;
  }


/* Custom toast close button */
.Toastify__close-button {
  width: 30px;
  height: 30px; 
  font-size: 20px;
  color: #fff;
  background-color: transparent;
  border: none;
  cursor: pointer; 
}

  