.button {
    background-color: #a881af;
    border: none;
    color: white;
    padding: 0.5rem 1rem;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 1rem;
    margin: 0.5rem;
    cursor: pointer;
    border-radius: 0.25rem;
  }
  
  .button:hover {
    background-color: #853f91;
  }
  