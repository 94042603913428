.input-container {
    position: relative;
  }
  
  .date-picker-wrapper {
    position: relative;
  }
  
  .date-picker-input {
    padding-right: 30px; /* Make space for the icon */
  }
  
  .date-picker-icon {
    position: relative;
    top: 50%;
    top: 5px;
    right: 30px;
    transform: translateY(-50%);
    pointer-events: none; /* Ensure the icon does not interfere with input focus/click */
  }
  
  .card-details{
    margin-left: 20%;
  }

  .card-body{
    width: 400px;
  }

  .card:nth-child(2){
  margin-left: 20px;
 
  }

  @media (max-width: 768px) {
    .card{
      margin-left: 0;
    }
    .card input{
      width: 100%;
    }

    .card-body{
      width: 300px;
    }
    .card:nth-child(2){
      margin-left: 0;
    }
    .card-details{
      margin-left: 0;
    }
    
  }
/* Attendance doctor page*/
  .calendar-container {
    overflow-x: auto;
    width: 100%;
  }

  @media (max-width: 768px) {
    .time-select-container {
      flex-direction: row;
      align-items: center;
    }
  
    .time-select-container .input-group {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
  
    .time-select-container label {
      margin-right: 10px;
    }
  }