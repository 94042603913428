.section1 {
  padding: 1rem;
  background-color: #f2c5fa;
  margin: 1rem 0;
  height: 300px;
  width: 300px;
  text-align: justify;
  margin-right: 100px;
  border-radius: 5px;
}

.section1 h2 {
  color: #333;
}

.image-container {
  position: relative;
  display: inline-block;
}

.image6 {
  margin-left: 50px;
  width: 500px; /* Adjust size as needed */
  height: auto;
  transition: transform 0.3s ease-in-out;
}

.image-container:hover .image6 {
  transform: translateY(-10px); /* Adjust movement as needed */
}

.tooltip {
  position: absolute;
  top: -5px; /* Adjust position as needed */
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(0, 0, 0, 0.75);
  color: #fff;
  padding: 5px 10px;
  border-radius: 4px;
  white-space: nowrap;
  z-index: 10;
  opacity: 0;
  transition: opacity 0.3s;
}

.image-container:hover .tooltip {
  opacity: 1;
}
