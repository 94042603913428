 .form4 {
    background: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 500px;
    margin-left: 500px;
    margin-top: 200px;
  } 
  
 form .admdiv {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
  } 
  
  .admdiv input[type="text"] {
    width: 48%;
    padding: 8px;
    border: 1px solid #853f91;
    border-radius: 4px;
  }  

  
  button {
    width: 100%;
    padding: 10px;
    background-color: #853f91;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
  }
  
  button:hover {
    background-color: #853f91;
  }
  
  @media (max-width: 600px) {
    form div {
      flex-direction: column;
    }
  
    input[type="text"] {
      width: 100%;
      margin-bottom: 10px;
    }
  }

  .success-message1 {
    margin-top: 20px;
    color: green;
    text-align: center;
    font-weight: bold;
  }

  @media (max-width: 768px) {
    .form4{
      margin-left: 0;
      margin-top: 100px;
    }
    .admdiv input[type="text"] {
      width: 100%;
    }
  }
  