
/*==========================
	Start Appointment CSS
============================*/
.appointment{
	background:#fff;
	padding-top:100px;
}
.appointment.single-page {
	background: #fff;
	padding-top: 100px 0;
	padding: 0;
	padding: 100px 0;
}
.appointment.single-page .appointment-inner {
	padding: 40px;
	box-shadow: 0px 0px 10px #00000024;
	border-radius: 5px;
}
.appointment.single-page .title{}
.appointment.single-page .title h3 {
	font-size: 25px;
	display: block;
	margin-bottom: 10px;
	font-weight:600;
}
.appointment.single-page .title p{}
.appointment .form{
	margin-top:30px;
}
.appointment .form .form-group{}
.appointment .form input {
	width: 100%;
	height: 50px;
	border: 1px solid #eee;
	text-transform: capitalize;
	padding: 0px 18px;
	color: #555;
	font-size: 14px;
	font-weight:400;
	border-radius:0;
	border-radius: 4px;
    
}
.appointment .form textarea{
	width: 100%;
	height:200px;
    padding: 18px;
	border:1px solid #eee;
	text-transform:capitalize;
	resize:none;
	border-radius: 4px;
}
.appointment .form-group .nice-select{
	width: 100%;
	height: 50px;
	line-height: 50px;
	border: 1px solid #eee;
	text-transform: capitalize;
	padding: 0px 18px;
	color: #999;
	font-size: 14px;
	font-weight:400;
	border-radius: 4px;
	font-weight:400;
}
.appointment .form-group .nice-select::after {
	right: 20px;
	color: #757575;
}
.appointment .form-group .list{
	border-radius: 4px;
}
.appointment .form-group .list li{
	color:#757575;
	border-radius:0;
}
.appointment .form-group .list li.selected{
	color:#757575;
	font-weight:400;
}
.appointment .form-group .list li:hover {
	color:#fff;
	background: #1A76D1;
}
.appointment .appointment-image{}
.appointment.single-page .button .btn{
	width:100%;
}
.appointment .button .btn{
	font-weight:500;
}
.appointment .button .btn:hover{
	color:#fff;
}
.appointment .form p{
	margin-top: 10px;
	color:#868686;
}
.appointment.single-page .work-hour{
	background:#1A76D1;
	padding: 40px;
	box-shadow: 0px 0px 10px #00000024;
	border-radius: 5px;
}
.appointment.single-page .work-hour h3 {
	font-size: 25px;
	display: block;
	font-weight:600;
	margin-bottom: 20px;
	color:#fff;
}
.appointment.single-page .time-sidual{
	margin-top:15px;
}
.appointment.single-page .time-sidual{
	overflow:hidden;
}
.appointment.single-page .time-sidual li {
	display: block;
	color: #fff;
	width: 100%;
	margin-bottom: 10px;
}
.appointment.single-page .time-sidual li span{
	display:inline-block;
	float:right;
}
.appointment.single-page .day-head .time {
	font-weight: 400;
	float: right;
}
.appointment-type-options {
	display: flex;
	gap: 20px;
  }
  
  .appointment-type-options label {
	display: flex;
	align-items: center;
	font-size: 14px;
	font-weight: normal;
  }
  
  .appointment-type-options input[type="radio"] {
	margin-right: 5px;
	transform: scale(0.9);
  }
  
/*==========================
	End Appointment CSS
============================*/

/* Style adjustments for mobile view */
@media (max-width: 768px) {
	.phone-input-group1 {
	  display: flex;
	  flex-direction: row;
	  align-items: center;
	  gap: 20px;
	}

	.form-control3 {
        
      }
  
	.phone-input-group .PhoneInput,
	.phone-input-group .PhoneInput input {
	  flex: 1;
	}
  
	.appointment-type-options {
	  display: flex;
	  flex-direction: row;
	  gap: 30px;
	  
	}
  
	.appointment-type-options label {
	  flex:0.2;
	  display: flex;
	  align-items: center;
	  justify-content: center;
	  font-size: 14px;
	  font-weight: normal;
	}
  
	.appointment-type-options input[type="radio"] {
	  margin-right: 5px;
	  transform: scale(0.9);
	}

	.form-control3{
		height: 14px;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		width: 210px;
	}

	.form-control4{
		width:210px;
	}
  }
